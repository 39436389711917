import React, { useEffect, useState } from "react";
import { TbMessage2Share } from "react-icons/tb";
import { Link, useOutletContext } from "react-router-dom";
import { readDocs } from "../api";
import { AnimatePresence } from "framer-motion";
import Demographics from "../components/graphs/Demographics";
import _ from "lodash";
import HourlyVisuals from "../components/graphs/HourlyVisuals";
import { HiCurrencyDollar, HiTruck, HiUser } from "react-icons/hi2";
import { IoDocument, IoFastFoodSharp } from "react-icons/io5";
import { CgShoppingBag } from "react-icons/cg";
import Messaging from "../components/Messaging";

const titles = [
  { title: "Orders", color: "bg-[#FD9773]" },
  { title: "Stores", color: "bg-[#017AFF]" },
  { title: "Users", color: "bg-[#F34971]" },
  { title: "Foods", color: "bg-[#39C0C8]" },
  { title: "Riders", color: "bg-[#4BE498]" },
  { title: "Earnings", color: "bg-[#C09EF7]" },
];

export default function Dashboard() {
  const { country } = useOutletContext();
  const [show, setShow] = useState(false);
  const [stores, setStore] = useState(0);
  const [users, setUsers] = useState([]);
  const [riders, setRiders] = useState(0);
  const [orders, setOrders] = useState([]);
  const [foods, setFoods] = useState(0);

  useEffect(() => {
    readDocs({
      path: `/order/all/${country?.name}`,
      setData: setOrders,
    });
    readDocs({
      path: `/store/all/${country?.name}`,
      setData: (data) => setStore(data.length),
    });

    readDocs({
      path: `/user/all/${country?.name}`,
      setData: setUsers,
    });

    readDocs({
      path: `/rider/all/${country?.name}`,
      setData: (data) => setRiders(data.length),
    });

    readDocs({
      path: `/food/all/${country?.name}`,
      setData: (data) => setFoods(data.length),
    });
  }, [country]);

  const getDemoGraph = () => {
    const grouped = _.groupBy(users, (usr) => usr.location?.city.trim());
    let results = [];
    Object.entries(grouped).forEach(([key, values]) => {
      results.push({ 
        city: key,
        total: Number(values.length).toLocaleString(),
        percentage:
          Number((values.length / users.length) * 100).toFixed(1) + "%",
      });
    });

    return results;
  };

  const earns = () => {
    let validOrders = _.filter(orders, ord => ["Ready", "Preparing", "Completed"].includes(ord.status))
    const returns = _.sumBy(validOrders, ord => ord.serviceCharge)

    return country?.currencySymbol + Number(returns).toFixed(2)
  }

  const stats = getDemoGraph();

  return (
    <div className="flex flex-col flex-1 p-5 space-y-5 overflow-y-auto">
      <div className=" flex flex-col space-y-3 p-2">
        <div className="flex gap-2 flex-wrap">
          <Card Icon={IoDocument} title={titles[0]} value={orders.length}  to={"/orders"} />
          <Card Icon={CgShoppingBag} title={titles[1]} value={stores} to={"/stores"} />
          <Card Icon={HiUser} title={titles[2]} value={users.length} to={"/users"} />
          <Card Icon={HiTruck} title={titles[4]} value={riders} to={"/riders"} />
          <Card Icon={IoFastFoodSharp} title={titles[3]} value={foods} to={"/items"} />
          <Card Icon={HiCurrencyDollar} title={titles[5]} value={earns()} to={"/finance"}/>
        </div>
        <div className="flex flex-1 py-5 px-5 bg-gray-50 rounded-md overflow-hidden">
          <HourlyVisuals orders={orders} />
        </div>
      </div>

      <div className="bg-white flex flex-col md:flex-row  gap-2  p-2">
        <div className="md:w-2/3 w-full">
          <div className="flex flex-col pb-3 ">
            <h3 className="font-bold text-lg">User Demographics</h3>
            <p className="text-sm">Statistics of user based on city</p>
          </div>
          <div className="flex flex-col w-full items-center gap-2">
            <table className="table-fixed w-full">
              <thead>
                <tr className="border-y bg-gray-100">
                  <th className="text-left font-semibold text-sm p-2">City</th>
                  <th className="text-left font-semibold text-sm p-2">Users</th>
                  <th className="text-left font-semibold text-sm p-2">
                    Percentage
                  </th>
                </tr>
              </thead>
              <tbody>
                {stats.map((info, idx) => (
                  <Td key={idx} info={info} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Demographics users={users} />

        <AnimatePresence>{show && <Messaging users={users} />}</AnimatePresence>

        <button
          onClick={() => setShow(!show)}
          className="fixed bg-red-500 text-white p-2 rounded-full z-10 bottom-4 right-5"
        >
          <TbMessage2Share size={30} />
        </button>
      </div>
      {/* Graph Design
       */}
    </div>
  );
}

const Td = ({ info }) => (
  <tr className=" border-b bg-white">
    <td className="p-2 text-sm font-bold">{info.city}</td>
    <td className="p-2 text-sm">{info.total}</td>
    <td className="p-2 text-sm">{info.percentage}</td>
  </tr>
);

const Card = ({ title, value, Icon, to }) => (
  <Link 
  to={to}
    className={`flex-1 lg:w-1/6 relative flex flex-col text-white  justify-center rounded-md border border-gray-100 p-5 ${title.color} `}
  >
    <p className="ml-5 text-left text-sm">{title.title}</p>
    <p className="ml-5 text-left font-bold text-2xl">{value || "NA"}</p>

    <Icon size={70} className=" absolute   left-3 opacity-20" />
  </Link>
);
