import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import Country from "../forms/settings/Reps";
import { readDocs } from "../api";
import { useOutletContext } from "react-router-dom";
import ServiceCharges from "../forms/settings/ServiceCharges";
import Promotions from "../forms/settings/Promotions";
import Referals from "../forms/settings/Referals";
import Reps from "../forms/settings/Reps";
import Payments from "../forms/settings/Payment";
import Store from "../forms/settings/Store";
import { HiAcademicCap, HiBanknotes, HiChartPie,  HiGift, HiShoppingCart, HiUserGroup } from "react-icons/hi2";

export default function Settings() {
  const { country } = useOutletContext();
  const [node, setNode] = useState("Promotion");

  const [currentTab, setCurrentTab] = useState(0);

  const [show, setShow] = useState("");
  const [stores, setStores] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {

    readDocs({
      path: `/store/all/${country?.name}`,
      setData: setStores,
    });


    readDocs({
      path: `/user/all/${country?.name}`,
      setData: setUsers,
    });
  }, [country]);

  const renderNode = (currentNode) => {
    switch (currentNode) {
      case "Promotion":
        return <Promotions stores={stores} country={country} />;

      case "Referal":
        return <Referals country={country} />;

      case "Charges":
        return <ServiceCharges country={country} users={users} />;

      case "Reps":
        return <Reps country={country} users={users} />;

      case "Payments":
        return <Payments stores={stores} country={country} />;

      case "Stores":
        return <Store stores={stores} country={country} />;

      default:
        return <Promotions stores={stores} country={country} />;
    }
  };

  const tabs = [
    {
      name: "Promotions",
      onClick: () => setNode("Promotion"),
      icon: () => <HiGift size={16} />
    },
    {
      name: "Referals",
      onClick: () => setNode("Referal"),
      icon: () => <HiUserGroup size={16} />
    },
    {
      name: "Service Charges",
      onClick: () => setNode("Charges"),
      icon: () => <HiChartPie size={16} />
    },
    {
      name: "Payments",
      onClick: () => setNode("Payments"),
      icon: () => <HiBanknotes size={16} />
    },
    {
      name: "Stores",
      onClick: () => setNode("Stores"),
      icon: () => <HiShoppingCart size={16} />
    },
    {
      name: "Reps",
      onClick: () => setNode("Reps"),
      icon: () => <HiAcademicCap size={16} />
    },
  ];

  return (
    <div className="flex-1 flex overflow-hidden bg-gray-50">
      <div className="flex-1 h-full  flex  flex-col items-center">
        <div className="flex flex-col h-full overflow-hidden  w-[80vw] shadow-xl   p-5 px-5  rounded-md">
          <div className="flex  w-fit flex-row items-center  sticky top-0  rounded bg-white"
            style={{
              clipPath: "polygon(0 0, 98% 0, 100% 100%, 0 100%)"
            }}
          >
            {tabs?.map((tb, indx) => (
              <button
                className={` pb-2 px-5 flex space-x-3 items-center pt-2 transition-all ${currentTab === indx
                    ? "  font-medium bg-yellow-400 text-white rounded-t  pr-10 shadow-md"
                    : "text-gray-400  font-light  px-2 "
                  } ${indx === tabs.length - 1 && "pr-10"}`}
                style={{
                  clipPath: "polygon(0 0, 90% 0, 100% 100%, 0 100%)"
                }}
                onClick={() => {
                  tb.onClick();
                  setCurrentTab(indx);
                }}
              >
                {tb.icon()}
                <p className="font-medium">{tb.name}</p>
              </button>
            ))}
          </div>

          <div className="flex flex-1 overflow-y-auto -mx-5">{renderNode(node)}</div>
        </div>
      </div>

      <AnimatePresence>
        {show === "country" && <Country close={() => setShow("")} />}
      </AnimatePresence>
    </div>
  );
}
