import _ from "lodash";
import Chart from "react-apexcharts";

var colorPalette = [
  "#00D8B6",
  "#008FFB",
  "#FEB019",
  "#FF4560",
  "#775DD0",
  "#FEB881",
  "#888888",
  "#A98884",
  "#BBE4C3",
];

export const options = (labels) => {
return  {
  chart: {
    type: "area",
    height: 350,
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  noData: {
    text: "No Order history to preview yet.",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: "14px",
    },
  },
  stroke: {
    curve: "smooth",
  },
  colors: colorPalette,
  title: {
    text: "Monthy Earning Statistics",
  },
  subtitle: {
    text: "Your earnings summary",
    align: "left",
  },
  labels,
  legend: {
    position: "bottom",
  },
}
}

export default function MonthlyVisuals({  orders }) {
  const getSeries = () => {
    const completed = [],
      cancelled = [], preparing = [], open = [],
      refund = [], labels = []

    const sorted = _.sortBy(orders, (ord) => new Date(ord.createdAt).getTime());

    // group sorted by month.
    const groupedByMonth = _.groupBy(sorted, (st) =>
      new Date(st.createdAt).toLocaleString("en", { month: "short" })
    );

  
    Object.entries(groupedByMonth).forEach(([key, values]) => {
      const comps = _.filter(values, (vl) => vl.status === "Completed").length;
      const cancel = _.filter(values, (vl) => vl.status === "Cancelled").length;
      const refs = _.filter(values, (vl) => vl.status === "Refund").length;
      const preps = _.filter(values, (vl) => vl.status === "Preparing").length;
      const opens  = _.filter(values, (vl) => vl.status === "Open").length;
      completed.push(comps)
      cancelled.push(cancel)
      refund.push(refs)
      preparing.push(preps)
      open.push(opens)
      labels.push(key)
    });

    let series = [
      {
        name: "Completed",
        data: completed,
      },
      {
        name: "Cancelled",
        data: cancelled,
      },
      {
        name: "Refund",
        data: refund,
      },
      {
        name: "Preparing",
        data: preparing,
      },
      {
        name: "Open",
        data: open,
      },
    ]

    return [series, labels]
  };

  return (
    <Chart
      type="area"
      options={options(getSeries()[1])}
      width={600}
      height={400}
      series={getSeries()[0]}
    />
  );
}
