import { motion } from "framer-motion";
import { useEffect, useState, useRef } from "react";
import { DEFAULT_FOOD } from "../../assets/defaults";
import { Feedback } from "../../components/Feedback";
import _ from 'lodash'

import { addDoc, uploadFile, readDocs, updateDoc } from "../../api";
import { HiChevronDown } from "react-icons/hi2";

import { LuUploadCloud } from "react-icons/lu";
import { useOutletContext } from "react-router-dom";

export default function AddFood({ info, storeID }) {
  const { country } = useOutletContext();

  const [food, setFood] = useState({
    ...DEFAULT_FOOD,
    currencyInitials: country.currencyInitials,
    currencySymbol: country.currencySymbol,
    storeID,
  });

  const [addons, setAddons] = useState([]);
  const [categories, setCategories] = useState([]);

  const imageRef = useRef();

  const [response, setResponse] = useState({
    open: false,
    message: "",
    type: "Error",
  });

  const [openCategory, setOpenCategory] = useState(false);

  useEffect(() => {
    readDocs({
      path: `/category/store/${storeID || info?.storeID}`,
      setData: setCategories,
    });
    readDocs({
      path: `/addon/${storeID || info?.storeID}`,
      setData: setAddons,
    });

    if (info?.edit) {
      setFood({ ...info, edit: true });
    }
  }, [info, storeID]);

  const addFood = async (e) => {
    e.preventDefault();
    const addon = _.find(addons, ad => ad._id === food?.addonID)?.items
    food.addons = addon 

    // console.log(info);
    if (food?.edit) {
      updateDoc({
        path: "/food",
        id: food._id,
        data: food,
        setResponse,
      });
      setFood({ ...DEFAULT_FOOD });
      return;
    }

    addDoc({
      path: "/food",
      data: {
        ...food,
        storeID,
        currencySymbol: country.currencySymbol,
        country: country?.name,
      },
      setResponse,
    });
    setFood({ ...DEFAULT_FOOD });
  };

  const setChecked = (e) => {
    setFood({ ...food, [e.target.name]: e.target.checked });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="bg-white  bottom-0 md:right-0 flex flex-col flex-1 h-full  w-full"
    >
      <div className="flex-1 overflow-y-auto px-5 pt-10">
        <div>
          <h3 className="text-2xl font-medium">
            {info?.edit ? "Update Food" : "Add new food"}
          </h3>
          <p className="text-sm text-gray-600 mt-2">
            Please enter your the information about the food you wish to add.
          </p>
        </div>
        <form onSubmit={addFood} className="flex flex-col space-y-5 mt-10">
          <div
            onClick={() => imageRef.current.click()}
            className={`h-20 w-full border border-gray-300 cursor-pointer transition-all delay-75 duration-100 hover:border-gray-500 rounded-md flex flex-col  items-center justify-center border-dashed`}
          >
            <LuUploadCloud
              size={30}
              color={food.photo === null ? "gray" : "green"}
            />
            <p className="text-sm text-gray-600">
              {food.photo !== null
                ? "Image Uploaded"
                : "Please click to upload food image"}
            </p>
            <input
              accept="image/*"
              ref={imageRef}
              onChange={(e) => {
                if (e.target.files === null) {
                  return;
                }
                uploadFile({
                  file: e.target.files[0],
                  folderName: "foods/" + new Date().getTime().toString(),
                  setData: (url) =>
                    setFood((prev) => ({ ...prev, photo: url })),
                  setResponse,
                });
              }}
              className="hidden"
              type="file"
              id="image"
            />
          </div>

          {/* <div className="h-44 border rounded overflow-hidden relative" >
            <img className="h-full w-full object-cover" alt="food_image" src={'https://images.unsplash.com/photo-1484723091739-30a097e8f929?q=80&w=1547&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} />
            <div className="absolute flex items-center justify-center flex-col top-0 left-0 right-0 bottom-0 hover:bg-black/30 cursor-pointer transition-all duration-100">
              <button className="bg-white/30 p-3 rounded-md">
                <p className="text-white">Change Image</p>
              </button>
            </div>
          </div> */}

          <div className="flex flex-col gap-2">
            <label className="text-gray-700 text-sm" htmlFor="name">
              Name of food
            </label>
            <input
              value={food.name}
              onChange={(e) =>
                setFood((prev) => ({ ...prev, name: e.target.value }))
              }
              required
              className="border outline-none p-3 px-5 rounded"
              placeholder="Enter the name of the food"
              id="name"
              type="text"
            />
          </div>

          <div className="flex flex-col gap-2">
            <label className="text-gray-700 text-sm" htmlFor="name">
              Tags
            </label>
            <input
              value={food.tags}
              onChange={(e) =>
                setFood((prev) => ({ ...prev, tags: e.target.value }))
              }
              required
              className="border outline-none p-3 px-5 rounded"
              placeholder="Enter Tags separated by comma (s)"
              id="name"
              type="text"
            />
          </div>

          <div className="flex flex-col gap-2">
            <label className="text-gray-700 text-sm" htmlFor="price">
              Price
            </label>
            <div className="w-full flex flex-row items-center border rounded">
              <p className="p-3 px-5 border-r font-bold">$</p>
              <input
                value={food.price}
                min={0}
                onChange={(e) =>
                  setFood((prev) => ({
                    ...prev,
                    price: parseFloat(e.target.value),
                  }))
                }
                className=" flex-1 outline-none p-3 pr-5 "
                placeholder="Enter price of food"
                id="price"
                type="number"
              />
            </div>
          </div>
          <div className="w-full flex flex-row items-center space-x-3">
            <div className="flex flex-col gap-2 flex-1 relative">
              <label className="text-gray-700 text-sm" htmlFor="name">
                Select Category
              </label>
              <button
                type="button"
                onClick={(e) => setOpenCategory((prev) => !prev)}
                className="border p-3 flex items-center rounded justify-between"
              >
                <p>{food?.category || " -- Select Category --"}</p>
                <HiChevronDown />
              </button>
              {openCategory && (
                <motion.div
                  initial={{ height: 0 }}
                  exit={{ height: 0 }}
                  animate={{ height: "fit-content" }}
                  className="w-full bg-white flex flex-col gap-3 border p-3 rounded"
                >
                  {categories?.map((info, idx) => (
                    <div
                      key={idx}
                      onClick={() => {
                        setFood((prev) => ({ ...prev, category: info.name }));
                        setOpenCategory(false);
                      }}
                      className="cursor-pointer"
                    >
                      {info.name}
                    </div>
                  ))}
                </motion.div>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <label className="text-gray-700 text-sm" htmlFor="description">
              Description
            </label>
            <textarea
              value={food.description}
              onChange={(e) =>
                setFood((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
              className="border outline-none rounded-md resize-none h-24 p-3 placeholder:text-sm placeholder:p-2"
              placeholder="Type a description to help customers know what they are buying."
            ></textarea>
          </div>

          <div className="flex flex-col gap-2">
            <label className="text-gray-700 text-sm" htmlFor="name">
              Select Addons
            </label>
            <button
              type="button"
              className="border p-3 flex items-center rounded justify-between"
            >
              <select
                onChange={(e) =>
                  setFood((prev) => ({ ...prev, addonID: e.target.value }))
                }
                value={food?.addonID}
                className=" flex-1 outline-none"
              >
                <option value="">--Selected Addons--</option>
                {addons.length > 0 &&
                  addons?.map((info, idx) => (
                    <option key={idx} value={info._id}>
                      {info.name}
                    </option>
                  ))}
              </select>
            </button>
          </div>

          <div className="flex  gap-3 pt-5 w-full">
            <div className="flex flex-col gap-2 w-1/2">
              <label className="text-gray-700 text-sm" htmlFor="startsAt">
                Available At
              </label>
              <input
                onChange={(e) =>
                  setFood((prev) => ({
                    ...prev,
                    startsAt: e.target.value,
                  }))
                }
                value={food?.startsAt}
                type="time"
                className="border  p-2 rounded-sm outline-none"
                id="startsAt"
              />
            </div>

            <div className="flex flex-col gap-2 w-1/2">
              <label className="text-gray-700 text-sm" htmlFor="endsAt">
                Ends At
              </label>
              <input
                type="time"
                id="endsAt"
                value={food?.endsAt}
                onChange={(e) =>
                  setFood((prev) => ({ ...prev, endsAt: e.target.value }))
                }
                className="border p-2 rounded-sm outline-none"
              />
            </div>
          </div>

          <div className="flex flex-col gap-3 pt-5">
            <div className="flex items-center  justify-between">
              <div className="flex items-center gap-2">
                <input
                  checked={food?.isVegan}
                  onChange={setChecked}
                  name="isVegan"
                  id="isVegan"
                  type="checkbox"
                />
                <label htmlFor="isVegan">Is Vegan</label>
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={food?.isAvailable}
                  onChange={setChecked}
                  name="isAvailable"
                  id="isAvailable"
                />
                <label htmlFor="isAvailable">Available</label>
              </div>

              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={food?.isPopular}
                  onChange={setChecked}
                  name="isPopular"
                  id="isPopular"
                />
                <label htmlFor="isPopular">Popular</label>
              </div>
            </div>
          </div>

          <div className="flex items-center space-x-3 text-white pt-10">
            <button
              disabled={!storeID}
              type="submit"
              className="flex-1 hover:bg-green-600 bg-green-500 font-medium p-4 rounded-md"
            >
              {food?.edit ? "Update Food" : "Save Food"}
            </button>
          </div>
        </form>
      </div>

      {response.open && (
        <Feedback {...response} close={() => setResponse({ open: false })} />
      )}
    </motion.div>
  );
}
