import React, { useEffect, useState } from "react";
import {
  addDoc,
  adminID,
  deleteDoc,
  readDocs,
  socket,
  updateDoc,
} from "../../api";
import { Feedback } from "../../components/Feedback";
import moment from "moment";
import _ from "lodash";
import { BsTrash } from "react-icons/bs";
import { TbEdit } from "react-icons/tb";

const chargeTypes = ["Country", "City"];

export default function ServiceCharges({ country, users }) {
  const [charges, setCharges] = useState({
    chargeType: "Country",
    country: country?.name,
    charge: 1.2,
    location: "" || country?.name,
  });

  const [code, setCode] = useState(0);
  const [showCreate, setShowCreate] = useState(false)

  const [response, setResponse] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const [servs, setServs] = useState([]);

  socket.on(adminID, (pl) => {
    if (pl === "charges") {
      setCode(new Date().getSeconds());
    }
  });

  useEffect(() => {
    readDocs({
      path: `/charges/all/${country?.name}?code=${code}`,
      setData: setServs,
    });
  }, [country, code]);

  const getField = (e) => {
    setCharges((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const getCities = () => {
    const grouped = _.groupBy(users, (usr) => usr.location?.city);
    let cities = [];
    Object.entries(grouped).forEach(([key, values]) => {
      cities.push({
        value: key,
        name: `${key}  (${values.length} Customers)`,
      });
    });

    return cities;
  };

  const createServiceCharge = (e) => {
    e.preventDefault();
    if (charges?.edit) {
      updateDoc({
        path: "/charges",
        id: charges._id,
        data: charges,
        setResponse,
      });

      setCharges((prev) => ({ ...prev, edit: false }));
      return;
    }

    addDoc({
      path: "/charges",
      data: charges,
      setResponse,
    });
  };

  const deleteCharges = (id) => {
    deleteDoc({
      path: "/charges",
      id,
      setResponse,
    });
  };

  return (
    <div className="flex w-full p-2">

      <div className="flex-1 p-3 ">
        <div className="w-full flex items-center justify-between py-5 px-2">
          <p className="text-lg font-semibold">Manage Charges.</p>
          <button onClick={() => setShowCreate(true)} className="bg-yellow-500 px-5 py-2 rounded text-sm text-white font-semibold">
            Create Charge
          </button>
        </div>
        <div className="py-2 align-middle inline-block min-w-full">
          <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Location
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Charge
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    created At
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {servs?.map((sv, idx) => (
                  <tr key={idx}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">

                        <div className="">
                          <div className="text-sm font-medium text-gray-900">{sv.location}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{country.currencySymbol} {sv.charge}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                    {moment(sv.createdAt).from()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex w-full items-center gap-2">
                        <button onClick={() =>{ deleteCharges(sv._id)}}>
                          <BsTrash size={18} color="red" />
                        </button>
                        <button onClick={() => { setShowCreate(true); setCharges({ ...sv, edit: true })}}>
                          <TbEdit size={18} color="green" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {response.open && (
          <Feedback {...response} close={() => setResponse({ open: false })} />
        )}
      </div>
      {showCreate && <div onClick={() => setShowCreate(false)} className="bg-black/50 z-10 backdrop-blur-sm top-0 left-0 bottom-0 right-0 absolute flex">
        <div onClick={e => e.stopPropagation()} className="w-1/3  h-fit p-5 mx-auto rounded-md mt-10 bg-white">
          <form
            action="#"
            onSubmit={createServiceCharge}
            className="flex flex-col gap-3"
          >
            <select
              onChange={getField}
              value={charges?.chargeType}
              name="chargeType"
              id="chargeType"
              className="p-3 bg-gray-100 outline-none"
            >
              <option value="">Charge Type</option>
              {chargeTypes.map((info) => (
                <option key={info} value={info}>
                  {info}{" "}
                </option>
              ))}
            </select>

            {charges.chargeType !== "Country" && (
              <select
                onChange={getField}
                value={charges?.location}
                name="location"
                id="location"
                className="p-3 bg-gray-100 outline-none"
              >
                <option value="">List of Cities</option>
                {getCities().map((info, idx) => (
                  <option key={idx} value={info.value}>
                    {info.name}
                  </option>
                ))}
              </select>
            )}

            {/* If country, continue */}

            <div className="flex flex-col">
              <label htmlFor="charge">Service Charge</label>
              <input
                value={charges.charge}
                type="number"
                min="0"
                step={0.01}
                onChange={(e) =>
                  setCharges((prev) => ({
                    ...prev,
                    charge: parseFloat(e.target.value),
                  }))
                }
                name="charge"
                className="p-2 border bg-gray-50 border-none"
                id="charge"
              />
            </div>

            <button
              className={`${charges?.edit ? "bg-green-500" : "bg-yellow-500"
                } text-white p-2`}
              type="submit"
            >
              {charges?.edit ? "Update Charges" : "Add Charges"}
            </button>
          </form>
        </div>
      </div>}
    </div>
  );
}
