import { Suspense, useEffect } from "react";
import Layout from "./layout";
import {
  createBrowserRouter,
  Route,
  Routes,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Orders from "./pages/Orders";
import Finance from "./pages/Finance";
import Users from "./pages/Users";
import Settings from "./pages/Settings";
import Riders from "./pages/Riders";
import Stores from "./pages/Stores";
import Login from "./pages/Login";
import { socket } from "./api";
import Items from "./pages/Items";
import Forgotpwd from "./pages/Forgotpwd";
import Resetpwd from "./pages/Resetpwd";

const router = createBrowserRouter([{ path: "*", Component: Root }]);
function App() {
  useEffect(() => {
    socket.on("connection", () => console.log("admin...connected"));
  }, []);
  return <RouterProvider router={router} />;
}

const Private = ({ children }) => {
  let authenticated = localStorage.getItem("adminLogged");

  return authenticated === null ? <Navigate to="/login" replace /> : children;
};

function Root() {
  return (
    <Suspense fallback={"Loading..."}>
      <Routes>
        <Route
          path=""
          element={
            <Private>
              <Layout />
            </Private>
          }
        >
          <Route path="/" element={<Dashboard />} />
          <Route path="/stores" element={<Stores />} />
          <Route path="/items" element={<Items />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/finance" element={<Finance />} />
          <Route path="/users" element={<Users />} />
          <Route path="/riders" element={<Riders />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<Forgotpwd />} />
        <Route path="/reset-password" element={<Resetpwd />} />
      </Routes>
    </Suspense>
  );
}

export default App;
