import {
  TbChartPieFilled
} from "react-icons/tb";
import { BiSolidShoppingBags } from "react-icons/bi";
import { IoSettings } from "react-icons/io5";
import { FaPiggyBank } from "react-icons/fa6";
import { IoStorefrontSharp } from "react-icons/io5";
import { HiUsers } from "react-icons/hi";
import { RiEBikeFill } from "react-icons/ri";

import { AiFillAppstore } from "react-icons/ai";

export const routes = [
  {
    title: "Dashboard",
    to: "/",
    icon: () => <TbChartPieFilled className="text-3xl md:text-xl" />,
  },
  {
    title: "Orders",
    to: "/orders",
    icon: () => <BiSolidShoppingBags className="text-3xl md:text-xl" />,
  },
  {
    title: "Stores",
    to: "/stores",
    icon: () => <IoStorefrontSharp className="text-3xl md:text-xl" />,
  },
  {
    title: "Foods",
    to: "/items",
    icon: () => <AiFillAppstore className="text-3xl md:text-xl" />,
  },
  {
    title: "Users",
    to: "/users",
    icon: () => <HiUsers className="text-3xl md:text-xl" />,
  },
  {
    title: "Riders",
    to: "/riders",
    icon: () => <RiEBikeFill className="text-3xl md:text-xl" />,
  },
  {
    title: "Finance",
    to: "/finance",
    icon: () => <FaPiggyBank className="text-3xl md:text-xl" />,
  },
  {
    title: "Settings",
    to: "/settings",
    icon: () => <IoSettings className="text-3xl md:text-xl" />,
  },
];
