export const DEFAULT_STORE = {
  country: "United Kingdom", // default United Kingdom
  phone: "",
  email: "",
  name: "",
  commissionRate: 0.7,
  fullAddress: "",
  city: "",
  landMark: "",
  pinCode: "",
  longitude:0, 
  latitude:0,
  coordinates: [0, 0],
  description: "",
  photo: null,
  minOrderPrice: 1.0,
  category: "Restaurant",
  licenseCode: "",
  opensAt: "00:00",
  closesAt: "00:00",
  isActive: true,
  openOnWeekends: false,
  serviceFee: 0.0,
  notificationToken: "",
};

export const COUNTRIES = [
  {
    name: "Ghana",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/40/Flag_of_Ghana.png",
    currencyName: "Ghanaian Cedi",
    currencySymbol: "₵",
    currencyInitials: "GHS",
    id: 1,
  },
  {
    name: "UK",
    flag: "https://upload.wikimedia.org/wikipedia/en/archive/a/ae/20190917170935%21Flag_of_the_United_Kingdom.svg",
    currencyName: "British Pound",
    currencySymbol: "£",
    currencyInitials: "GBP",
    id: 2,
  },
];

export const DEFAULT_FOOD = {
  name: "",
  price: 0,
  photo: null,
  description: "",
  addonID: "",
  currencySymbol:"",
  currencyInitials:'',
  category: "",
  tags:[], 
  status: "New",
  addons: [],
  startsAt: "00:00",
  endsAt: "18:00",
  isVegan: false,
  isPopular: false,
  isAvailable: false,
  type: "",
};
