export const StoreCard = ({ setStore,info, store, id }) => (
  <button
    onClick={() => setStore(info)}
    className={`flex items-center w-full ${store?._id === id ? 'bg-blue-500 text-white' :'' } border-b border-gray-200/50 p-3  gap-2`}
  >
    <img
    alt={info?.name}
      src={info?.photo}
      className="w-5 h-5 rounded-full bg-green-500"
    />
    <p className="text-sm">{info?.name} </p>
  </button>
);
