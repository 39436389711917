import { NavLink } from "react-router-dom";

export const Navlink = ({ to, title, children }) => (
  <NavLink
    to={to}
    className={({ isActive }) =>
      `${
        isActive ? "bg-[#7063e6] text-white font-semibold rounded-sm py-4 my-2 -mx-2 px-5" : "text-gray-500"
      } flex flex-row items-center py-2 space-x-2 px-4 rounded-lg gap-2`
    }
  >
    {children} <p className="text-base  md:hidden lg:flex">{title}</p>
  </NavLink>
);
