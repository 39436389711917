import React, { useEffect, useState } from "react";
import { readDocs } from "../api";
import moment from "moment";
import Header from "../components/Header";
import { useOutletContext } from "react-router-dom";
import _ from "lodash";
import { StoreCard } from "../components/Cards";
import OrderInfoModal from "../components/OrderDetails";

import { HiChevronDown, HiShoppingBag } from "react-icons/hi2";

const orderStatus = [
  "Open",
  "Ready",
  "Cancelled",
  "Refund",
  "Preparing",
  "Completed",
];

export default function Orders() {
  const { country } = useOutletContext();
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState({});
  const [order, setOrder] = useState(null);
  const [orders, setOrders] = useState([]);
  const [active, setStatus] = useState(orderStatus[0]);
  const [showStoreList, setShowStoreList] = useState(false);

  useEffect(() => {
    readDocs({
      path: `/store/all/${country?.name}`,
      setData: setStores,
    });

    readDocs({
      path: `/order/all/${country?.name}`,
      setData: setOrders,
    });
  }, [country]);

  const filtered = () => {
    return _.filter(stores, (st) => st.country === country?.name);
  };

  const filterOrders = () => {
    // console.log(store._id);
    // console.log(orders);
    return store._id === undefined
      ? orders
      : _.filter(orders, (od) => od.storeID === store._id);
  };

  const getStats = (status) => {
    const orders = filterOrders();
    const filtered = orders.filter((ord) => ord.status === status);

    return [filtered, filtered.length];
  };

  return (
    <div className="flex-1 flex flex-col space-y-5">
      <div className="p-2 flex-col space-y-10 px-5 pt-5">
        <Header
          title={"Manage Orders"}
          description={
            "Get an indepth view on the orders made buy users on the system and also sort them based on the order status and restaurants' specific orders."
          }
        />
        <div className="flex gap-2 mt-5">
          <div className="px-3 py-3">
            <p className="text-xs text-gray-500 mb-1">Selected Store</p>
            <button
              onClick={() => setShowStoreList(true)}
              className={`flex items-center text-gray-600 border p-3 rounded-xl shadow-sm gap-2`}
            >
              <img
                alt={store?.name}
                src={store?.photo}
                className="w-5 h-5 rounded-full bg-gray-500"
              />
              <p className="text-sm">{store?.name || "Select Store"} </p>

              <HiChevronDown />
            </button>
            {showStoreList && (
              <>
                <div className="absolute z-20 bg-white/70 backdrop-blur-sm h-80 w-80 border border-gray-400/50 shadow-md mt-2 rounded-md overflow-hidden ">
                  <div className=" overflow-x-hidden flex p-2 pt-3 border-b border-gray-200/50">
                    <input
                    disabled
                      type="text"
                      placeholder="Search Store ..."
                      className="border outline-none rounded-lg text-xs p-2 flex-1  bg-gray-50"
                    />
                  </div>
                  <div className="flex gap-2  flex-col items-center">
                    {filtered().map((info, idx) => (
                      <StoreCard
                        key={idx}
                        info={info}
                        store={store}
                        id={info?._id}
                        setStore={(data) => {
                          setStore(data);
                          setShowStoreList(false);
                        }}
                      />
                    ))}
                  </div>
                </div>
                <div
                  onClick={() => setShowStoreList(false)}
                  className="absolute top-0 left-0 bottom-0 right-0 z-10"
                ></div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="flex  p-2 w-full items-center px-5">
        {orderStatus.map((status, idx) => (
          <Card
            status={status}
            activeStatus={active}
            setStatus={setStatus}
            value={getStats(status)[1]}
            key={idx}
            Icon={HiShoppingBag}
          />
        ))}
      </div>
      <div className="flex-1 px-5 overflow-y-auto">
        <table className="table-auto w-full mt-2">
          <thead>
            <tr className="border-y bg-gray-200">
              <th className="text-left font-semibold p-2 text-sm text-gray-500">
                Order ID
              </th>
              {/* <th className="text-left font-semibold p-2 text-sm text-gray-500">User</th> */}
              <th className="text-left font-semibold p-2 text-sm text-gray-500">
                Item
              </th>
              <th className="text-left font-semibold p-2 text-sm text-gray-500">
                Price
              </th>
              <th className="text-left font-semibold p-2 text-sm text-gray-500">
                Charge
              </th>
              <th className="text-left font-semibold p-2 text-sm text-gray-500">
                Date
              </th>
            </tr>
          </thead>
          <tbody>
            {getStats(active)[0]?.map((od, idx) => (
              <tr
                className="border-b py-2 cursor-pointer"
                key={idx}
                onClick={() => setOrder(od)}
              >
                <td className="p-2 text-sm py-3 text-green-700">#{od.id}</td>

                <td className="p-2 text-sm py-3">{od?.items[0].name}</td>
                <td className="p-2 text-sm py-3">
                  {country?.currencySymbol}
                  {od.totalPrice}
                </td>
                <td className="p-2 text-sm py-3">
                  {country?.currencySymbol}
                  {Number(od.serviceCharge).toFixed(2)}
                </td>
                <td className="p-2 text-sm py-3 font-light text-gray-600">
                  {moment(od.createdAt).from()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {order && <OrderInfoModal order={order} close={() => setOrder(null)} />}
      </div>
    </div>
  );
}

const Card = ({ status, value, setStatus, Icon, activeStatus }) => (
  <button
    onClick={() => setStatus(status)}
    className={`flex flex-col p-4 w-[30.5%] lg:w-1/6 bg-white ${activeStatus === status ? 'border-b-2 border-green-500' : 'border'} rounded-md relative m-1`}
  >
    <p className="text-sm text-gray-500">{status}</p>
    <p className="text-xl font-bold">{value || "N/A"}</p>

    <Icon size={40} className="absolute right-5 opacity-20" />
  </button>
);
