import React, { useEffect, useState } from "react";
import { maxDate } from "../../functions";
import { Feedback } from "../../components/Feedback";
import moment from "moment";
import {
  addDoc,
  deleteDoc,
  updateDoc,
  adminID,
  readDocs,
  socket,
} from "../../api";
import { BsTrash } from "react-icons/bs";
import { TbEdit } from "react-icons/tb";

const awardTypes = ["Token", "Money"];

export default function Referals({ country }) {
  const [ref, setRef] = useState({
    token: 0.3,
    awardType: "Token",
    startsAt: "",
    usableAt: 100,
    worth: 1, // equivalent to a unit of the currency.
    country: country?.name,
    endsAt: "",
    description: "",
  });
  const [showCreate, setShowCreate] = useState(false)

  const [refs, setRefs] = useState([]);
  const [code, setCode] = useState(0);

  const [response, setResponse] = useState({
    open: false,
    type: "error",
    message: "",
  });

  socket.on(adminID, (pl) => {
    if (pl === "refs") {
      setCode(new Date().getSeconds());
    }
  });

  useEffect(() => {
    readDocs({
      path: `/referal/all/${country?.name}?code=${code}`,
      setData: setRefs,
    });
  }, [code, country]);

  const getField = (e) => {
    setRef((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const createReferal = (e) => {
    e.preventDefault();
    if (ref?.edit) {
      updateDoc({
        path: "/referal",
        id: ref._id,
        data: ref,
        setResponse,
      });

      setRef((prev) => ({ ...prev, edit: false, _id: null }));
      return;
    }
    addDoc({
      path: "/referal",
      data: ref,
      setResponse,
    });
  };

  const deleteRef = (id) => {
    deleteDoc({
      path: "/referal",
      id,
      setResponse,
    });
  };

  return (
    <div className="flex w-full p-2">

      <div className="flex-1 p-2">
        <div className="w-full flex items-center justify-between py-5 px-2">
          <p className="text-lg font-semibold">Manage Referals.</p>
          <button onClick={() => setShowCreate(true)} className="bg-yellow-500 px-5 py-2 rounded text-sm text-white font-semibold">
            Create Reward
          </button>
        </div>
        <div className="py-2 align-middle inline-block min-w-full">
          <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Token
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Usable At
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Worth
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Ends
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {refs?.map((info, idx) => (
                  <tr key={info.email}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">

                        <div className="">
                          <div className="text-sm font-medium text-gray-900">{info.description}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{info.awardType}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {info.token}{" "}
                      {info?.awardType === "Token"
                        ? "coins"
                        : country.currencySymbol}{" "}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{info?.usableAt || ""}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{info?.worth || ""}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{moment(info.endsAt).from()}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex w-full items-center gap-2">
                        <button
                          onClick={() => { deleteRef(info._id); }}
                        >
                          <BsTrash size={18} color="red" />
                        </button>
                        <button onClick={() => { setShowCreate(true); setRef({ ...info, edit: true }) }}>
                          <TbEdit size={18} color="green" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {showCreate && <div onClick={e => setShowCreate(false)} className="bg-black/40 backdrop-blur-sm absolute top-0 left-0 right-0 bottom-0 z-10">
        <div onClick={e => e.stopPropagation()} className="w-1/3  mx-auto bg-white mt-10  h-fit rounded-md overflow-hidden p-5">
          <form
            onSubmit={createReferal}
            action="#"
            className="flex flex-col gap-3 p-3 overflow-y-auto"
          >
            <div className="flex flex-col ">
              <label htmlFor="message">Description</label>
              <input
                required
                type="text"
                name="description"
                id="description"
                value={ref?.description}
                onChange={getField}
                placeholder="Description"
                className="p-2 bg-gray-50 rounded-md border outline-none"
              />
            </div>

            <div className="flex items-center space-x-2">
              <div className="flex flex-col flex-1">
                <label htmlFor="awardType">Award Type</label>
                <select
                  required
                  id="awardType"
                  name="awardType"
                  value={ref?.awardType}
                  onChange={getField}
                  className="p-2 bg-gray-50 rounded-md border outline-none"
                >
                  <option value="">Type</option>
                  {awardTypes.map((info, idx) => (
                    <option key={idx} value={info}>
                      {info}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col flex-1">
                <label htmlFor="token">Token</label>
                <input
                  required
                  name="token"
                  id="token"
                  value={ref?.token}
                  type="number"
                  min="0"
                  onChange={(e) =>
                    setRef((prev) => ({
                      ...prev,
                      token: parseFloat(e.target.value),
                    }))
                  }
                  placeholder="Token Worth"
                  className="p-2 bg-gray-50 rounded-md border outline-none"
                />
              </div>
            </div>

            {ref.awardType === "Token" && (
              <div className="flex gap-2 w-full">
                <div className="flex flex-col w-1/2">
                  <label htmlFor="usableAt">Usable At</label>
                  <input
                    required
                    type="text"
                    name="usableAt"
                    id="usableAt"
                    value={ref?.usableAt}
                    onChange={getField}
                    placeholder="Token Usable At"
                    className="p-2 bg-gray-50 rounded-md border outline-none"
                  />
                </div>

                <div className="flex flex-col w-1/2">
                  <label htmlFor="worth">Amount Per Token</label>
                  <input
                    required
                    name="worth"
                    id="worth"
                    type="number"
                    min="1"
                    value={ref?.worth}
                    onChange={getField}
                    placeholder="Token Worth"
                    className="p-2 bg-gray-50 rounded-md border outline-none"
                  />
                </div>
              </div>
            )}

            <div className="flex w-full gap-3">
              <div className="flex flex-col w-1/2">
                <label htmlFor="startsAt">Starts On</label>
                <input
                  type="date"
                  required
                  min={maxDate()}
                  name="startsAt"
                  id="startsAt"
                  value={ref?.startsAt.slice(0, 10)}
                  onChange={getField}
                  className="p-2 bg-gray-50 rounded-md border outline-none"
                />
              </div>
              <div className="flex flex-col w-1/2">
                <label htmlFor="endsAt">Ends At</label>
                <input
                  required
                  min={maxDate()}
                  type="date"
                  value={ref?.endsAt.slice(0, 10)}
                  onChange={getField}
                  name="endsAt"
                  id="endsAt"
                  className="p-2 bg-gray-50 rounded-md border outline-none"
                />
              </div>
            </div>
            <button
              className={`p-2 ${ref?.edit ? "bg-green-400" : "bg-yellow-500"
                } text-white rounded-md`}
            >
              {ref?.edit ? "Update Scheme" : "Create Scheme"}
            </button>
            {response.open && (
              <Feedback
                {...response}
                close={() => setResponse({ open: false })}
              />
            )}
          </form>
        </div>
      </div>}
    </div>
  );
}
