import React from "react";

export default function Header({ title, description }) {
  return (
    <div className="flex flex-1 items-center justify-between ">
      <div className="flex flex-col space-y-3 flex-1">
        <h3 className="text-3xl text-gray-800 font-bold">{title}</h3>
        <p className="text-sm text-gray-400 w-2/3">
          {description}
        </p>
      </div>
    </div>
  );
}
