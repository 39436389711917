import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { HiMinus, HiPlus } from "react-icons/hi2";
import { TbTrash, TbEdit, TbHeartsOff } from "react-icons/tb";
import { Feedback } from "../../components/Feedback";
import moment from 'moment'
import { addDoc, deleteDoc, readDocs} from "../../api";

export default function Addons({ viewOption, storeID }) {

  const [name, setName] = useState("");
  const [addons, setAddons] = useState([]);
  const [newAddons, setNewAddons] = useState([{description: "",price: 0,}]);
  const [addon, setAddon] = useState({});

  const [response, setResponse] = useState({
    open: false,
    message: "",
    type: "error",
  });

  useEffect(() => {

    readDocs({
      path: `/addon/${storeID}`,
      setData: setAddons,
    });
  }, [storeID]);


  const createAddon = (e) => {
    e.preventDefault();
    addDoc({
      path: "/addon",
      data: {
        storeID,
        name,
        items: newAddons,
      },
      setResponse,
    }); 
  };

  return (
    <motion.div
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      exit={{ y: 100 }}
      className="bg-white bottom-0 md:right-0 flex flex-col flex-1 h-[70vh] md:h-[90vh] w-full "
    >
      <div className="flex-1 overflow-y-auto px-5 pt-10">
        {viewOption === 0 && (
          <>
            {" "}
            <div className="flex justify-between items-center mb-5">
              <div className="flex flex-col gap-2 pb-2">
                <h3 className="text-xl font-bold">Addons</h3>
                <p className="text-sm text-gray-500">
                  Add a new addon to your menu.
                </p>
              </div>
            </div>
            <form onSubmit={createAddon} className="flex flex-col space-y-5">
              <div className="flex flex-col gap-2">
                <label htmlFor="name" className="text-sm text-gray-600">
                  Name of Addon
                </label>
                <input
                  id="name"
                  placeholder="Enter name of Addon"
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  className="outline-none border rounded p-3"
                />
              </div>
              {newAddons.map((item, index) => (
                <div
                  key={index}
                  className="flex w-full  overflow-hidden flex-row items-center gap-3"
                >
                  <div className="flex w-1/2 md:w-2/3 flex-col gap-2">
                    <label className="text-sm text-gray-600">
                      Description
                    </label>

                    <input
                      onChange={(e) =>
                        setNewAddons((prev) => {
                          return prev.map((item, i) => {
                            if (i === index) {
                              return {
                                description: e.target.value,
                                price: item.price,
                              };
                            }
                            return item;
                          });
                        })
                      }
                      className="border p-3 rounded outline-none"
                      type="text"
                      placeholder="Addons name"
                    />
                  </div>
                  <div className="flex flex-1 shrink-0 flex-col gap-2 overflow-hidden">
                    <label htmlFor="price" className="text-sm text-gray-600">
                      Price
                    </label>
                    <div className="flex  border overflow-hidden flex-1 rounded flex-row items-center">
                      <p className="border-r p-3 px-5">$</p>
                      <input
                        value={item.price}
                        required
                        onChange={(e) =>
                          setNewAddons((prev) => {
                            return prev.map((item, i) => {
                              if (i === index) {
                                return {
                                  description: item.description,
                                  price: e.target.valueAsNumber,
                                };
                              }
                              return item;
                            });
                          })
                        }
                        name="price"
                        type="number"
                        step={0.01}
                        placeholder="Price"
                        className="p-3 outline-none w-full"
                      />
                    </div>
                  </div>
                  <div className="hidden md:flex flex-col items-end justify-end h-16">
                    <button
                      type="button"
                      onClick={() =>
                        setNewAddons((prev) => {
                          const newArray = [...prev];
                          newArray.splice(index, 1);
                          return newArray;
                        })
                      }
                      className=" hidden md:flex items-center justify-center bg-red-100 p-2 rounded-full"
                    >
                      <HiMinus color="red" size={10} />
                    </button>
                  </div>
                </div>
              ))}

              <div>
                <button
                  type="button"
                  onClick={() =>
                    setNewAddons((prev) => [...prev, { name: "", price: 0 }])
                  }
                  className="bg-gray-100 px-5 py-2 rounded-md"
                >
                  <HiPlus color="gray" size={20} />
                </button>
              </div>

              <div className="flex flex-col gap-2">
                <label className="text-sm text-gray-600">Description</label>
                <textarea
                  value={addon?.description}
                  required
                  onChange={(e) =>
                    setAddon({ ...addon, description: e.target.value })
                  }
                  name="description"
                  type="text"
                  placeholder="Description"
                  className="border outline-none w-full rounded resize-none p-3"
                />
              </div>

              <button
                disabled={!storeID}
                type="submit"
                className="border p-4 rounded text-sm font-medium text-white bg-green-500"
              >
                {addon?.edit ? "Update" : "Save"} Addons
              </button>
            </form>
          </>
        )}

        <div className="flex flex-col gap-3">
        {viewOption === 1 && (
            <div className="flex flex-col gap-5">
              <h3 className="text-xl font-bold">Saved Addons</h3>
              <table className="table-auto w-full">
                <thead>
                  <tr className="border bg-gray-200 font-semibold">
                    <td className="p-2 pl-4 text-sm">Name</td>
                    <td className="p-2 text-sm">Addons</td>
                    <td className="p-2 text-sm">Created</td>
                    <td className="p-2 text-sm"></td>
                  </tr>
                </thead>
                <tbody>
                  {addons?.map((info, idx) => (
                    <tr key={idx} className="border-b">
                      <td className="text-sm font-normal pl-3 py-3">
                        {info.name}
                      </td>
                      <td className="text-sm font-normal p-2">{info?.items.length} </td>
                      <td className="text-sm font-normal p-2">
                        {moment(info.createdAt).from()}
                      </td>
                      <td className="text-sm font-normal p-2">
                        <div className="flex gap-2 items-center">
                          <button
                            title="Edit"
                            onClick={() => setAddon({ ...info, edit: true })}
                          >
                            <TbEdit className="text-lg text-gray-500" />
                          </button>
                          <button
                            title="Delete"
                            onClick={() => deleteDoc({
                                    path: "/addon",
                                    id: info._id,
                                    setResponse,
                                  })
                            }
                          >
                            <TbTrash className="text-lg text-red-500" />
                          </button>
                          <button title={"Toggle Status "}>
                            <TbHeartsOff className="text-lg text-green-500" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      {response.open && (
        <Feedback {...response} close={() => setResponse({ open: false })} />
      )}
    </motion.div>
  );
}
