import { useEffect, useState } from "react";
import Food from "../forms/items/Food";
import Items from "../forms/items/Items";
import Addons from "../forms/items/Addons";
import { motion } from "framer-motion";
import { HiChevronLeft, HiPlus } from "react-icons/hi2";
import { HiViewList } from "react-icons/hi";
import { readDocs } from "../api";
import { StoreCard } from "./Cards";
import { useOutletContext } from "react-router-dom";

export default function AddItem({ close, info }) {
  const [active, setActive] = useState("foods");
  const [formView, setformView] = useState(0);
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState({});
  const { country } = useOutletContext();

  const [showStoreList, setShowStoreList] = useState(false);

  useEffect(() => {
    readDocs({
      path: `/store/all/${country?.name}`,
      setData: setStores,
    });
  }, [country]);

  useEffect(() => {
    setShowStoreList(false);
  }, [store]);

  const renderNode = (formView) => {
    const nodes = [
      {
        component: <Food info={info} storeID={store?._id || info?.storeID} />,
        name: "foods",
      },
      {
        component: (
          <Addons
            viewOption={formView}
            i
            storeID={store?._id || info?.storeID}
          />
        ),
        name: "addons",
      },
      {
        component: (
          <Items viewOption={formView} storeID={store?._id || info?.storeID} />
        ),
        name: "categories",
      },
    ];

    return nodes.find((nd) => nd.name === active).component;
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="z-30 fixed bg-black-500/10 flex flex-row  items-center justify-center bottom-0 backdrop-blur-sm left-0 md:left-none md:right-0 flex-1 h-[85vh] md:h-screen  overflow-hidden w-screen"
    >
      <motion.div className="w-4/5 lg:w-1/2 relative p-5  bg-white border flex flex-col h-[95%] rounded-md   overflow-hidden border-gray-100 shadow-md">
        <div
          onClick={() => setShowStoreList(true)}
          className="border border-gray-100 w-fit px-3 py-2 rounded-md mb-2"
        >
          <p className="text-gray-500 text-sm">
            {store?.name || "Select a Store"}
          </p>
        </div>
        {showStoreList && (
          <>
            <div className="w-[80%] absolute top-16 bg-white z-10 h-60 py-2 border rounded border-gray-100 px-5  rounded-x-full">
              {stores.map((str, idx) => (
                <StoreCard
                info={str}
                  store={store}
                  setStore={setStore}
                  id={str?._id}
                  key={idx}
                />
              ))}
            </div>
            <div
              onClick={() => setShowStoreList(false)}
              className="absolute top-0 left-0 right-0 bottom-0"
            ></div>
          </>
        )}
        <div className="flex gap-10 w-full p-2 items-center">
          <button
            onClick={close}
            className="bg-gray-100 shrink-0 flex items-center justify-center h-10 w-10 rounded-full"
          >
            <HiChevronLeft size={18} className="text-gray-500" />
          </button>

          <div className="flex flex-row items-center gap-6 rounded-lg px-2 py-2 flex-1">
            <button
              onClick={() => setActive("foods")}
              className={`flex flex-row items-center gap-2  text-sm ${
                active === "foods"
                  ? "border-black border-b-2 font-semibold text-gray-800"
                  : "text-gray-500"
              } py-2`}
            >
              <p className="">Foods</p>
            </button>

            <button
              onClick={() => setActive("addons")}
              className={`flex flex-row items-center gap-2  text-sm ${
                active === "addons"
                  ? "border-black border-b-2 font-semibold text-gray-800"
                  : "text-gray-500"
              } py-2`}
            >
              <p className="">Addons</p>
            </button>

            <button
              onClick={() => setActive("categories")}
              className={`flex flex-row items-center gap-2  text-sm ${
                active === "categories"
                  ? "border-black border-b-2 font-semibold text-gray-800"
                  : "text-gray-500"
              } py-2`}
            >
              <p className="">Categories</p>
            </button>
          </div>
          {active !== "foods" && active !== "categories" && (
            <div className="">
              <div className="flex transition-all delay-100 flex-row w-20 h-10 space-x-3 items-center bg-gray-100 px-5 relative py-2 rounded-full">
                <button
                  title="All foods including Vegan foods"
                  onClick={() => setformView(0)}
                  className={`${
                    formView ? "" : "bg-white"
                  }  p-2 transition-all animate delay-75 rounded-full absolute left-1`}
                >
                  <HiPlus size={15} className="text-gray-500" />
                </button>
                <button
                  title="All Vegan foods only"
                  onClick={() => setformView(1)}
                  className={`${
                    formView ? "bg-white" : ""
                  } absolute right-1  p-2 transition-all animate delay-75 rounded-full`}
                >
                  <HiViewList size={15} className="text-gray-500" />
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-col p-1 h-full flex-1 overflow-y-auto">
          {renderNode(formView)}
        </div>
      </motion.div>
    </motion.div>
  );
}
