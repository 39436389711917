import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { readDocs, tempImage } from "../api";
import moment from "moment";
import { useOutletContext } from "react-router-dom";
import _ from 'lodash'
import OrderInfoModal from "./OrderDetails";

export default function UserProfile({ user, setUser }) {
  const [history, setHistory] = useState([]);
  const [order, setOrder] = useState(null)
  const { country } = useOutletContext();

  useEffect(() => {
    readDocs({
      path: `/user/history/${user._id}`,
      setData: setHistory,
    });
  }, [user]);


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex fixed top-0 backdrop-blur-sm z-50 w-full left-0 h-screen"
    >
      <div
        onClick={() => setUser(null)}
        className="flex-1 bg-gray-100/5 backdrop-blur-sm"
      ></div>
      <div className="w-2/3 relative bg-white p-5 flex flex-col ">
        <div className="absolute top-10 right-10">
          <div className="flex">
            <div className="w-fit px-3 py-2 flex-items-center bg-gray-500 text-white">
              <p className="text-xs">Account Balance</p>
              <p className="text-lg">
                {country.currencySymbol  + ' ' + 
                  Number(user?.accountBalance || 0 ).toLocaleString()}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col  gap-2 p-2">
          <img
            src={user?.photo || tempImage}
            alt="User Name"
            className="w-20 h-20 object-cover bg-gray-100 rounded-full border"
          />
          <div className="flex flex-col gap-1">
            <h1 className="text-lg leading-relaxed font-bold">
              {user?.name}
            </h1>
            <h1 className="text-sm">{user?.email} | {user.location?.fullAddress}</h1>
            
            <div className="flex gap-2 items-center">
              <p className="font-bold text-sm">({history?.length}) Orders </p>
              <a
                className="text-sm text-blue-600"
                href={`https://eyepadelivery.com/signup?referrer=${user?.refID}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Referrer Link
              </a>
            </div>
          </div>
        </div>
        {/* Usage History */}
        <div className="flex flex-col h-[80vh] overflow-y-auto pt-2">
          <table className="table-fixed w-full">
            <thead>
              <tr className="border-y bg-gray-100">
                <th className="text-left p-2 font-semibold">Order ID</th>
                <th className="text-left p-2 font-semibold">Total Price</th>
                <th className="text-left p-2 font-semibold">Status</th>
                <th className="text-left p-2 font-semibold">Date</th>
                <th className="text-left p-2 font-semibold">PIN</th>
              </tr>
            </thead>
            <tbody>
              {history.map((info, idx) => (
                <tr className="border-b cursor-pointer" key={idx} onClick={() => setOrder(info)}>
                  <td className="p-2">#{info.id}</td>
                  <td className="p-2">{info.totalPrice}</td>
                  <td className="p-2">{info.status}</td>
                  <td className="p-2">{moment(info.createdAt).from()}</td>
                  <td className="p-2">"N/A"</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={4} className="p-2">Total Spent: <span className="font-bold">{country.currencySymbol} {Number(_.sumBy(history, hi => hi.totalPrice)).toLocaleString()}</span></td>
              </tr>
            </tfoot>
          </table>
        </div>
        {order && <OrderInfoModal order={order} close={() => setOrder(null)} /> }
      </div>
    </motion.div>
  );
}
